import { PortableText } from "components/UI/PortableText/PortableText"
import React, { useContext, useEffect } from "react"
import { useLocation } from "react-use"
import { ThemeContext, ThemeProvider } from "styled-components"
import { SanityMarkdown } from "typings/graphql"
import { ModeType } from "typings/modules"
import { Container } from "./Markdown.styles"
import { useHeaderHeight } from "hooks/useHeaderHeight"

type Props = {
  index: number
} & SanityMarkdown

export const Markdown = (sanityProps: Props) => {
  const location = useLocation()
  const headerHeight = useHeaderHeight()
  const themeContext = useContext(ThemeContext)
  const alignment = sanityProps.alignment ?? "left"
  const textMode = sanityProps.textTheme as ModeType
  useEffect(() => {
    const hash = window.location.hash
    if (!!hash) {
      const buffer = 80
      const selector = hash.replace("/", "")
      const el = document.querySelector(selector) as any
      const headerHeightWithBuffer = headerHeight + buffer
      const offset = el?.offsetTop
      if (offset) {
        window.scrollTo(0, offset - headerHeightWithBuffer)
      }
    }
  }, [location, headerHeight])
  return (
    <ThemeProvider
      theme={{
        localTheme: themeContext[textMode],
      }}
    >
      <Container
        $bgColor={sanityProps.bgColor?.opacityHex}
        $textAlign={alignment}
        base="1 [10] 1"
        md="2 [12] 2"
        lg="6 [12] 6"
      >
        {sanityProps._rawContent && (
          <PortableText blocks={sanityProps._rawContent} $mode={textMode} />
        )}
      </Container>
    </ThemeProvider>
  )
}
