import styled from "styled-components"
import { mq } from "styles"
import { Grid } from "components/UI/Grid/Grid"
import { $BgColor, $TextAlign } from "typings/modules"

export const Container = styled(Grid)<$BgColor & $TextAlign>`
  background: ${({ $bgColor }) => $bgColor};
  text-align: ${({ $textAlign }) => $textAlign};
  padding: 64px 0;
  ${mq.minWidth("lg")} {
    padding: 96px 0;
  }
`
