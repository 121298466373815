import React from "react"
import { SanityMarkdown } from "typings/graphql"
import { Markdown } from "./Markdown"

export const SanityMarkdownBlock = ({
  index,
  fields,
}: {
  fields: SanityMarkdown
  index: number
}) => <Markdown index={index} {...fields} />

export default SanityMarkdownBlock
